<template>
  <!-- v-if解决center弹窗位置问题 -->
  <div
    :class="direction === 'center' ? 'centers' : ''"
    v-if="direction === 'center' ? visible : true"
  >
    <div
      class="product-window"
      :style="{
        width: width + '%',
        height:
          height == 'fit-content' ? height : visible ? height : 'fit-content',
        background: backgroundColor,
        'border-radius': borderRadius
      }"
      :class="(visible ? 'on' : '') + ' ' + direction"
    >
      <!-- 兼容h5顶部导航空位 -->
      <!-- #ifndef MP -->
      <div
        v-if="direction !== 'below' && direction !== 'center'"
        style="height: 50px"
      ></div>
      <!-- #endif -->
      <div
        v-if="is_close"
        @click="close"
        class="iconfont"
        :style="{
          width: 160 - 2 * iconWidth + 'px',
          height: 160 - 2 * iconWidth + 'px',
          top: '0px',
          right: '0px'
        }"
      >
        <img
          src="https://vip.cdn.weekey.com.cn/image/2021/10-15/023d322e0f95f6d00cd89cddb4f023c2.png"
          :style="{
            width: iconWidth + 'px',
            height: iconWidth + 'px',
            top: iconWidth + 'px',
            right: iconWidth + 'px'
          }"
          :class="
            direction !== 'below' && direction !== 'center' ? 'iconfont-h5' : ''
          "
          class="iconfont"
        />
      </div>
      <slot />
    </div>
    <div
      class="mask"
      v-if="is_mask"
      @touchmove.prevent
      :hidden="!visible"
      @click="close(1)"
      :style="{ opacity: maskOpacity }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    // 弹窗方向 { below: 下边, up: 上边, left: 左边, right: 右边, center: 中间 }
    direction: {
      type: String,
      default: 'center'
    },
    // close图标大小
    iconWidth: {
      type: Number,
      default: 28
    },
    // 弹框内容的固定宽度
    width: {
      type: Number,
      default: 100
    },
    // 弹框内容的固定高度
    height: {
      type: String,
      default: 'fit-content'
    },
    // 是否显示关闭按钮
    is_close: {
      type: Boolean,
      default: true
    },
    // 是否显示遮罩层
    is_mask: {
      type: Boolean,
      default: true
    },
    // 是否点击遮罩关闭弹窗
    maskFun: {
      type: Boolean,
      default: true
    },
    // 禁止滑动
    forbidSlide: {
      type: Boolean,
      default: true
    },
    // 背景颜色
    backgroundColor: {
      type: String,
      default: '#fff'
    },
    // 蒙层透明度
    maskOpacity: {
      type: String,
      default: '.6'
    },
    // 边框圆角
    borderRadius: {
      type: String,
      default: '8px'
    }
  },
  data() {
    return {
      visible: false
    }
  },
  watch: {
    visible: {
      handler(newval) {
        document.body.style.overflow = newval ? 'hidden' : 'initial'
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    show() {
      this.visible = true
      this.$emit('popupShow')
    },
    close(e) {
      if (e == 1 && !this.maskFun) return
      this.visible = false
      this.$emit('popupClose')
    }
  }
}
</script>

<style lang="less" scoped>
view {
  box-sizing: border-box;
}

.centers {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  //   left: 0;
  right: 0;
  //   bottom: 0;
}

.product-window {
  position: fixed;
  z-index: 9999;
  // padding: 25px 15px;
  transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.below {
  left: 0;
  bottom: 0;
  transform: translate3d(0, 100%, 0);
  -webkit-transform: translate3d(0, 100%, 0);
}

.up {
  top: 0;
  left: 0;
  transform: translate3d(0, -100%, 0);
  -webkit-transform: translate3d(0, -100%, 0);
}

.right {
  right: 0;
  top: 0;
  height: 100%;
  transform: translate3d(100vw, 0, 0);
  -webkit-transform: translate3d(100vw, 0, 0);
}

.left {
  left: 0;
  top: 0;
  height: 100%;
  transform: translate3d(-100vw, 0, 0);
  -webkit-transform: translate3d(-100vw, 0, 0);
}

.center {
  position: static;
  -webkit-position: static;
  transform: translate3d(-100vw, -100%, 0);
  -webkit-transform: translate3d(-100vw, -100%, 0);
}

.product-window.on {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  // opacity: .5;
  z-index: 9000;
}

.product-window .iconfont {
  position: fixed;
  // right: 15px;
  // top: 10px;
  font-size: 16px;
  color: #8a8a8a;
  width: 25px;
  height: 25px;
}

//兼容h5顶部导航空位
// #ifndef MP
.product-window .iconfont-h5 {
  top: 50px;
}

// #endif
</style>
