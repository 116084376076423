/* 
  上传相关业务逻辑
*/

import { $ajaxService } from './request'

import { service } from './request'

/**
 * 文件上传接口
 */
export const fileUpload = (body) =>
  service.post($ajaxService.baseUrl + 'file/upload', body)

/**
 * 通过media_id换取上传文件的文件url
 * @param  {} file_type 文件上传类型：1-图片，2-视频，3-文件，4-语音
 * @param  {} media_id 微信返回的media_id
 * @param  {} file_id 文件标识id
 */
export const getUploadByMedia = (body) =>
  service.post($ajaxService.baseUrl + 'file/upload_by_media', body)
